.k3study_ielets-testimonial-card-rating {
  text-align: center;
}

.k3study_ielets-testimonial-card-name {
  text-align: center;
}

.k3study_ielets-testimonial-card:hover {
  color: var(--color-bg);
  background-color: var(--color-blue);
}

.k3study_ielets-testimonial-card p {
  text-align: start;
  font-size: 0.7rem;
  font-weight: 500;
}

.k3study_ielets-testimonial-card-stars {
  width: 100%;
  text-align: start;
}

.k3study_ielets-testimonial-card {
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid var(--color-grey);
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  /* margin: 0 2rem; */
}

.k3study_ielets-testimonial-card_img-container {
  padding: 3.5rem;
}

.k3study_ielets-testimonial-card-img {
  position: absolute;
  top: -4rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0.8rem;
  border-radius: 70%;
  border: 1px solid var(--color-bg);
  background: linear-gradient(108.46deg,
      rgba(255, 255, 255, 0.264) 0%,
      rgba(255, 255, 255, 0.066) 100%);
  backdrop-filter: blur(8.42857px);
}

.k3study_ielets-testimonial-card_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.8rem;
  margin-inline: auto;
  height: 100%;
}

.k3study_ielets-testimonial_stars {
  display: flex;
}

.k3study_ielets-testimonial-card-main {
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 4rem;
}

.k3study_ielets-testimonial-card_user-img {
  font-size: 3rem;
}

.k3study_ielets-testimonial-card_user-details_name {
  font-size: 0.9rem !important;
  font-weight: 600 !important;
}
.k3study_ielets-testimonial-card-stars img{
width: 0.8rem;
}
@media screen and (max-width: 950px) {
  .k3study_ielets-testimonial-card-main {
    display: grid;
    grid-template-columns: auto auto;
  }

  .k3study_ielets-testimonial-card p {
    font-size: 0.8rem;
  }

  .k3study_ielets-testimonial-card {
    margin: 2rem 1rem;
  }

}

@media screen and (max-width: 550px) {
  .k3study_ielets-testimonial-card-main {
    grid-template-columns: 1fr;
  }

  .k3study_ielets-testimonial-card {
    margin: 1rem;
  }
}