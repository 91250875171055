@import url("https://fonts.googleapis.com/css2?family=Kanit&family=Lato:wght@300;400;700;900&family=Pacifico&family=Permanent+Marker&family=Poppins:ital,wght@0,100;0,200;0,300;0,600;0,700;1,200;1,400;1,500;1,600&family=Uchen&display=swap");


:root {
  --font-family: "Poppins";
  --color-blue: #2968e9;
  --color-red: #eb5757;
  --color-yellow: #f2c94c;
  --color-violet: #9b51e0;
  --color-peach: #ffede1;
  --color-cream: #faffd4;
  --color-lavender: #f8e7f8;
  --color-bg: #ffffff;
  --color-bg-gradient: linear-gradient(
    113.28deg,
    rgba(173, 173, 173, 0.755) -13.95%,
    rgba(227, 227, 227, 0.708) 86.85%
  );
  --color-paragraph: #828282;
  --color-darkblue: #00aeff;
  --color-counter-bg: #193e8c;
  --color-navbar-links: #333333;
  --color-skyblue: #ccedff;
  --color-text: #4f4f4f;
  --color-img-bg: #effaff;
  --color-grey: #828282;
  --color-skyblue-dark: #61cffe;
  --color-footer-bg: #171e6f;
  --color-quote-bg: #90caf9;
  --color-training-heading1: #addaff;
  --color-heading: #0f2a3d;
  --color-light-grey: #e7e7e7;
  --color-white: #ffffff;
}
