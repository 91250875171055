.k3study_call-experts {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-counter-bg);
  padding: 1.5rem 3rem;
  font-size: 2rem;
  font-weight: 500;
  color: var(--color-bg);
}
.k3study_button {
  background-color: var(--color-blue);
  color: var(--color-bg);
  padding: 1rem 5rem;
  border: none;
  border-radius: 10px;
  font-size: 2rem;
  font-weight: 600;
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .k3study_call-experts {
    align-items: center;
    font-size: 1rem;
  }
  .k3study_button {
    padding: 0.5rem 2rem;
    font-size: 1rem;
  }
}
