.k3study_about-gmat_main {
  padding: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.k3study_about-gmat-bold {
  font-weight: 600;
}
.k3study_pte_span {
  font-weight: 600;
}
.k3study_about-gmat-paragraph {
  line-height: 2rem;
  font-weight: 400;
  word-spacing: 0.04em;
  text-align: justify;
}
.k3study_about-gmat_li {
  list-style: disc;
  padding: 0.5rem;
  text-align: justify;
}
@media screen and (max-width: 900px) {
  .k3study_about-gmat_main {
    padding: 4rem 2rem;
    text-align: center;
  }
  .k3study_about-gmat-list {
    margin-inline: 2rem;
  }
}
@media screen and (max-width: 550px) {
  .k3study_about-gmat_main {
    padding: 1rem;
  }
  .k3study_about-gmat-bold {
    font-weight: 600;
  }
}
