.k3study_ieletslist-container{
  display: flex;
  flex-direction: column;
  margin-inline-start: 4rem;
  gap: 2rem;
  margin-bottom: 2rem;
}
.k3study_ieletslist-main {
  display: flex;
}
.k3study_ieletslist {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-inline-start: 4rem;
}
.k3study_ieletslist-components {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.k3study_ieletslist-icon {
  background-color: var(--color-skyblue);
  border-radius: 20rem;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  justify-content: center;
}

.k3study_ieletslist-text {
  font-weight: 600;
}
.k3study_ieletslist-img {
  width: 50%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 950px) {
  .k3study_ieletslist-main {
    display: flex;
    margin: 2rem;
  }
  .k3study_ieletslist {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-inline-start: 2rem;
  }
  .k3study_ieletslist-components {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .k3study_ieletslist-icon {
    background-color: var(--color-skyblue);
    border-radius: 20rem;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .k3study_ieletslist-text {
    font-weight: 600;
    font-size: 1rem;
  }
  .k3study_ieletslist-img {
    width: 50%;
    display: flex;
    justify-content: center;
  }
  .k3study_ieletslist-girl-img {
    width: 20rem;
    object-fit: cover;
  }
}
@media screen and (max-width: 550px) {
  .k3study_ieletslist-main {
    flex-direction: column-reverse;
  }
  .k3study_ieletslist {
    width: 100%;
    margin-inline-start: 0rem;
  }
  .k3study_ieletslist-icon {
    width: 3rem;
    height: 3rem;
  }
  .k3study_ieletslist-img {
    width: 100%;
  }
}
