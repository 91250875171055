.k3study_toelf-training-heading1 {
  color: var(--color-training-heading1);
  font-weight: 550;
  text-align: center;
  font-size: 2rem;
}
.k3study_toelf-training-heading2 {
  color: var(--color-bg);
  font-weight: 500;
  text-align: center;
  font-size: 3rem;
}
.k3study_toelf-training-button {
  color: var(--color-bg);
  background-color: var(--color-blue);
  border: none;
  border-radius: 10px;
  padding: 1rem;
  font-weight: 530;
  font-size: 1rem;
  align-items: center;
}
.k3study_toelf-training-main {
  position: relative;
}
.k3study_toelf-training-content {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  height: 100%;
  padding: 6rem 0rem;
  line-height: 5rem;
}
.k3study_toelf-training_img {
  width: 100%;
  height: 30em;
  object-fit: cover;
  overflow: hidden;
}

@media screen and (max-width: 550px) {
  .k3study_toelf-training-heading1 {
    font-size: 1.5rem;
  }
  .k3study_toelf-training-heading2 {
    font-size: 2rem;
  }
}
