.k3study_rating-main {
  position: relative;
  background-image: url("../../Assets/Images/Ielets/background.png");
  background-size: 100% 100%;
  height: 50rem;
  padding: 5rem 2rem;
  display: flex;
  align-items: center;
}

.k3study_ielets-rating {
  align-items: center;
  background-color: var(--color-blue);
  display: flex;
  padding: 4rem 4rem;
  margin-inline: 3rem;
  gap: 1rem;
}

.k3study_ielets-rating-section1 {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.k3study_ielets-rating-section2 {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.k3study_rating-img_div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;
}

.k3study_text {
  color: var(--color-bg);

}

.k3study_ielets-rating-quotes {
  padding: 1rem;
  background: linear-gradient(108.46deg,
      rgba(255, 255, 255, 0.264) 0%,
      rgba(255, 255, 255, 0.066) 100%);
  backdrop-filter: blur(7.95238px);
  border-radius: 50%;
  position: absolute;
  top: 1rem;
  left: 10rem;
  width: 7rem;
}

.k3study_ielets-rating-arrow {
  padding: 1rem 2rem;
  background-color: var(--color-bg);
  align-items: center;
  display: flex;
  border-radius: 0.5rem;
  gap: 3rem;
}

.k3study_text {
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 3rem;
}

.k3study_text-paragraph {
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-bg);
  line-height: 1.8rem;
}

.k3study_rating-img {
  width: 10rem;
}

@media screen and (max-width:1100px) {

  /* .k3study_rating-main {
    height: 40rem;
    padding: 0rem;
  } */
  .k3study_rating-main {
    background-image: none;
    height: auto;
    padding: 0;
  }

  .k3study_ielets-rating {
    flex-direction: column;
    padding: 2rem;
  }

  .k3study_rating-img_div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }

  .k3study_ielets-rating-section1 {
    width: 100%;
    gap: 1rem;
    padding: 1rem;
    text-align: center;
  }

  .k3study_ielets-rating-section2 {
    width: 100%;
    gap: 1rem;
  }

  .k3study_rating-img {
    width: 10rem;
  }

  .k3study_ielets-rating-quotes {
    display: none;
  }

  .k3study_ielets-rating-arrow {
    padding: 0.5rem 1rem;
  }

  .k3study_text-paragraph {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 550px) {
  .k3study_rating-main {
    background-image: none;
    height: auto;
  }

  .k3study_ielets-rating {
    padding: 2rem 0rem;
    margin-inline: 0rem;
  }

  .k3study_rating-img_div {
    grid-template-columns: auto;
    gap: 0rem;
  }

  .k3study_text {
    font-size: 1rem;
  }

  .k3study_text {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .k3study_text-paragraph {
    line-height: 1.5rem;
  }
}