.k3study__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3.5rem;
  border-bottom: 2px solid var(--color-grey);
}
.k3study__header-links_container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.k3study__header-links_li {
  color: var(--color-header-links);
  font-weight: bold;
  font-size: 0.9rem;
}
.k3study__header-links_li_registration {
  color: var(--color-blue);
  font-weight: 600;
}
.k3study__header_phone {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4rem;
}
.k3study__header_phone-number {
  font-weight: 600;
}
.k3study__header-links_login-btn {
  background: var(--color-blue);
  border: none;
  border-radius: 0.2rem;
  padding: 0.7rem 1.2rem;
  color: var(--color-bg);
  font-weight: 500;
}
.k3study__header-links_social {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media screen and (max-width: 900px) {
  .k3study__header {
    flex-direction: column;
    padding: 0.5rem 0rem;
  }
  .k3study__header-links_container {
    justify-content: space-between;
    width: 100%;
    padding: 0rem 0.8rem;
  }
  .k3study__header-links_social {
    gap: 1.2rem;
  }
}

@media screen and (max-width: 550px) {
  .k3study__header {
    padding: 0rem 0.5rem;
  }
  .k3study__header-links_social {
    gap: 1rem;
  }
  .k3study__header-links_li {
    color: var(--color-header-links);
    font-weight: bold;
    font-size: 1rem;
  }
  .k3study__header-links_li_registration {
    color: var(--color-blue);
    font-weight: 600;
    font-size: 0.8rem;
  }
  .k3study__header_phone {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.4rem;
  }
  .k3study__header_phone-number {
    font-weight: 600;
    font-size: 0.8rem;
  }
  .k3study__header-links_login-btn {
    background: var(--color-blue);
    border: none;
    border-radius: 0.2rem;
    padding: 0.5rem 1rem;
    color: var(--color-bg);
    font-weight: 500;
  }
  .k3study__header_icons {
    width: 1.5rem;
  }
}
