.k3study_hero-carousal-component-main {
  position: relative;
  letter-spacing: 0.02em;
}
.k3study_hero-carousal_bg-img {
  width: 100%;
}
.k3study-hero-carousal-component-carousel-button {
  color: var(--color-blue);
}
.k3study_hero-carousal-component_carousal_main {
  position: absolute;
  top: 2rem;
  display: flex;
  padding: 2rem 4rem;
  width: 100%;
}
.k3study_hero-carousal-component_carousal-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  justify-content: center;
  width: 70%;
}
.k3study_hero-carousal-component_heading {
  font-size: 3.8rem;
  color: var(--color-bg);
  width: 90%;
  text-align: left;
  line-height: 4rem;
  font-weight: 600;
}
.k3study_hero-carousal-component_para {
  color: var(--color-bg);
  font-size: 1.25rem;
  max-width: 45rem;
  text-align: left;
}
.k3study_hero-carousal-component_carousal_content_btns {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.k3study_hero-carousal-component_all-course-btn {
  padding: 1rem 4rem;
  border-radius: 0.5rem;
  white-space: nowrap;
  background: var(--color-blue);
  color: var(--color-bg);
  border: none;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.04em;
}
.k3study_hero-carousal-component_playbtn {
  width: 50%;
  display: flex;
  gap: 1rem;
  align-items: center;
  color: var(--color-bg);
  white-space: nowrap;
  font-weight: 600;
}
.k3study_hero-carousal-component_carousal_enquiry-form {
  width: 30%;
}
.k3study_hero-carousal-component_enquiry-form_div {
  width: 20rem;
  margin-inline: auto;
}
.k3study_hero-carousal-component_enquiry-form_title {
  background: var(--color-bg);
  border-top-left-radius: 19.2px;
  border-top-right-radius: 19.2px;
  color: var(--color-blue);
  padding: 1rem 0rem;
  font-weight: 600;
  text-align: center;
}
.k3study_hero-carousal-component_enquiry-form_inputs {
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.624) 0%,
    rgba(255, 255, 255, 0.156) 100%
  );
  backdrop-filter: blur(9.6px);
  border-bottom-left-radius: 19.2px;
  border-bottom-right-radius: 19.2px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
}
.k3study_hero-carousal-component_enquiry-form_desc {
  font-size: 0.7rem;
  text-align: left;
  color: var(--color-bg);
}
.k3study_hero-carousal-component_enquiry-form_input {
  border: 1px solid var(--color-bg);
  color: var(--color-bg);
  outline: none;
  box-shadow: none;
  border-radius: 0.4rem;
  background: none;
  padding: 0.5rem 1rem;
}
.k3study_hero-carousal-component_enquiry-form_input::placeholder {
  color: var(--color-bg);
}
.k3study_hero-carousal-component_submit-btn {
  padding: 0.6rem 2rem;
  border-radius: 0.5rem;
  white-space: nowrap;
  background: var(--color-blue);
  color: var(--color-bg);
  border: none;
  margin-inline: auto;
}

@media screen and (max-width: 950px) {
  .k3study_hero-carousal-component_carousal_main {
    top: 0.5rem;
    flex-direction: column;
    padding: 1rem 1rem;
  }
  .k3study_hero-carousal-component_carousal-content {
    gap: 0rem;
    align-items: center;
    width: 100%;
    padding-bottom: 1rem;
  }
  .k3study_hero-carousal-component_heading {
    font-size: 1.5rem;
    width: 100%;
    margin-bottom: 0.5rem;
    line-height: 1.8rem;
    text-align: center;
  }
  .k3study_hero-carousal-component_para {
    font-size: 0.8rem;
    text-align: center;
    font-weight: 500;
  }
  .k3study_hero-carousal-component_carousal_content_btns {
    gap: 0.5rem;
    justify-content: center;
  }
  .k3study_hero-carousal-component_all-course-btn {
    display: none;
  }
  .k3study_hero-carousal-component_playbtn {
    display: none;
  }
  .k3study_hero-carousal-component_carousal_enquiry-form {
    width: auto;
  }
  .k3study_hero-carousal-component_enquiry-form_div {
    width: 16rem;
  }
  .k3study_hero-carousal-component_enquiry-form_title {
    color: var(--color-blue);
    padding: 0.5rem 0rem;
  }
  .k3study_hero-carousal-component_enquiry-form_inputs {
    gap: 0.3rem;
    padding: 0.5rem 1.2rem;
  }
  .k3study_hero-carousal-component_enquiry-form_desc {
    font-size: 0.5rem;
  }
  .k3study_hero-carousal-component_enquiry-form_input {
    padding: 0.4rem 0.8rem;
  }
  .k3study_hero-carousal-component_enquiry-form_input::placeholder {
    font-size: 0.7rem;
  }
  .k3study_hero-carousal-component_submit-btn {
    padding: 0.3rem 1.5rem;
  }
  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    padding: 0;
  }
  .k3study_hero-carousal_carousel_indicators {
    width: 0.9rem !important;
  }
  .k3study_hero-carousal_carousel_indicator-selected {
    width: 1.9rem !important;
  }
  .k3study_carousel_img {
    width: 100%;
    height: 35em;
    object-fit: cover;
    overflow: hidden;
  }
}
