.k3study_contact_main {
  position: relative;
  background-color: var(--color-blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0rem;
}
.k3study_contact_text {
  width: 50%;
  font-size: 2.8rem;
  color: var(--color-bg);
  padding-inline-start: 3rem;
  font-weight: 700;
}
.k3study_contact_img {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.k3study_contact-skyblue {
  height: 100%;
  max-width: 35rem;
}
.k3study_contact-contact {
  padding: 2rem;
  background-color: var(--color-bg);
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 700;
  border-radius: 1rem;
  gap: 1rem;
}
@media screen and (max-width: 980px) {
  .k3study_contact_main {
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0rem;
  }
  .k3study_contact_text {
    font-size: 1.2rem;
    padding-inline-start: 0rem;
    text-align: center;
    padding: 1rem;
    width: 100%;
  }
  .k3study_contact-skyblue {
    width: 100%;
  }
  .k3study_contact-contact {
    font-size: 1rem;
    gap: 0.5rem;
    white-space: nowrap;
    padding: 1rem;
  }
}
