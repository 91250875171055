.k3study_about-ielets {
  padding: 1rem 2rem;
  text-align: center;
}
.k3study_about-ielets_title {
  text-align: center;
  font-size: 3rem;
}
.k3study_about-ielets_title-div {
  padding: 2rem;
}
.k3study_about-ielets-blue {
  color: var(--color-blue);
}
.k3study-about-ielets-paragraph {
  color: var(--color-paragraph);
  text-align: center;
  width: 70%;
  margin-inline: auto;
}
.k3study-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem 5rem;
}
@media screen and (max-width: 900px) {
  .k3study_about-ielets {
    padding: 1rem 1rem;
  }
  .k3study_about-ielets_title {
    font-size: 1.5rem;
  }
  .k3study-about-ielets-paragraph {
    width: auto;
  }
  .k3study-card-container {
    flex-direction: column;
    gap: 1rem;
    padding: 0rem 1rem;
  }
}
