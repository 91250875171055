.k3study_counter-rating {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
.k3study_counter-main {
  display: flex;
  justify-content: space-around;
  background-color: var(--color-counter-bg);
  padding: 2rem 0rem;
}
.k3study_counter-section {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.k3study_counter-heading {
  color: var(--color-darkblue);
  text-align: center;
  font-size: 1.8rem;
}

.k3study_counter-paragraph {
  text-align: center;
  font-weight: 500;
  font-size: 0.9rem;
  color: var(--color-white);
}
.k3study_hero-counter {
  background-color: var(--color-counter-bg);
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 900px) {
  .k3study_counter-main {
    padding: 1rem 0.5rem;
    gap: 1rem;
  }
  .k3study_counter-heading {
    font-size: 0.9rem;
  }

  .k3study_counter-paragraph {
    font-size: 0.7rem;
    font-weight: 500;
  }
  .k3study_counter-rating_img {
    width: 1rem;
  }
}
