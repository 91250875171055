.k3study-benefits_main {
  padding: 5rem 4rem;
  display: grid;
  grid-template-rows: auto auto;
  gap: 1rem;
}
.k3study-benefits-blue {
  color: var(--color-blue);
}
.k3study-benefits_title {
  font-size: 3rem;
  font-weight: 600;
}
.k3study_benefits-our-benefits {
  font-weight: 600;
  font-size: 1.2rem;
}
.k3study-benefits_content {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 2rem;
}
.k3study-benefits-text {
  font-weight: 700;
}
.k3study-benefits-sections {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-img-bg);
  border-radius: 1rem;
  padding: 2rem;
  position: relative;
}
.k3study-benefits_hover-desc {
  position: absolute;
  bottom: 0;
  width: fit-content;
  padding: 1rem;
  background-color: var(--color-skyblue-dark);
  border-radius: 0rem 0rem 1rem 1rem;
  color: var(--color-bg);
  letter-spacing: 0.03rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  justify-content: flex-start;
  height: 0;
  opacity: 0;
  transition: 0.5s ease;
}
.k3study-benefits-desc {
  font-size: 0.8rem;
}
.k3study-benefits-sections:hover .k3study-benefits_hover-desc {
  height: 12rem;
  opacity: 1;
}
.k3study_benefits-img {
  width: 100%;
  max-height: 20rem;
  object-fit: cover;
  overflow: hidden;
}
@media screen and (max-width: 980px) {
  .k3study-benefits_main {
    padding: 1rem 1rem;
    text-align: center;
  }
  .k3study-benefits_title {
    font-size: 1.5rem;
  }
  .k3study_benefits-our-benefits {
    font-size: 1rem;
  }
  .k3study-benefits_content {
    grid-template-columns: auto auto;
    gap: 1rem;
    padding: 0rem 1rem;
  }
  .k3study-benefits-sections {
    padding: 1rem;
  }
  .k3study_benefits-img {
    max-height: 15rem;
    padding: 2rem;
  }
}
@media screen and (max-width: 650px) {
  .k3study-benefits_content {
    grid-template-columns: auto;
  }
  .k3study-benefits-sections {
    justify-content: center;
  }
}
