.k3study_contact {
  padding: 5rem;
}
.k3study_contact-card-main {
  display: flex;
  border: 1px solid var(--color-paragraph);
  border-radius: 1rem;
  gap: 2rem;
  width: auto;
  margin-inline: auto;
}
.k3study_contact-card-button {
  color: var(--color-bg);
  background-color: var(--color-blue);
  padding: 1rem 3rem;
  max-width: 10rem;
  border: none;
  border-radius: 0.2rem;
  margin: 0 auto;
  font-size: 1.2rem;
}
.k3study_contact-card-section1 {
  padding: 2rem;
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.k3study_contact-card-section1_title {
  font-size: 2.5rem;
}
.k3study_contact-card-inputs {
  display: flex;
  gap: 2rem;
  width: 100%;
}
.k3study_contact-card-input {
  background-color: var(--color-light-grey);
  border: none;
  outline: none;
  padding: 1rem;
  border-radius: 0.5rem;
  max-width: 100%;
  resize: none;
}
.k3study_contact-card-email,
.k3study_contact-card-message {
  display: grid;
  gap: 1rem;
  width: 100%;
}
.k3study_contact-card-email-input {
  background-color: var(--color-light-grey);
  border: none;
  outline: none;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  width: 100%;
}
.k3study_contact-card-section1 h6 {
  font-size: 1rem;
  color: var(--color-text);
  font-weight: 550;
}
.k3study_contact-card-name,
.k3study_contact-card-lname {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.k3study_contact-card-section2 {
  width: 40%;
  color: var(--color-bg);
  background-color: var(--color-quote-bg);
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  border-radius: 1rem;
}
.k3study_contact-card_email-phone {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.k3study_contact-card-contact-info {
  text-align: center;
  width: 100%;
}
.k3study_contact-card-icons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 0rem 2rem;
}
.k3study_contact-card_location {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.k3study_contact-card_details {
  display: flex;
  font-size: 1.2rem;
  font-weight: 600;
}
.k3study_contact-card-location-input {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
.k3study_contact-card_desc {
  font-size: 0.8rem;
}
@media screen and (max-width: 950px) {
  .k3study_contact {
    padding: 3rem;
  }
  .k3study_contact-card-main {
    flex-direction: column;
    width: 100%;
  }
  .k3study_contact-card-button {
    border: none;
    border-radius: 0.5rem;
  }
  .k3study_contact-card-section1 {
    padding: 1rem;
    width: 100%;
  }
  .k3study_contact-card-section1_title {
    font-size: 1.5rem;
    white-space: nowrap;
  }
  .k3study_contact-card-inputs {
    /* flex-direction: column; */
    gap: 1rem;
  }
  .k3study_contact-card-name {
    display: grid;
    grid-template-columns: auto;
    gap: 1rem;
  }
  .k3study_contact-card-section2 {
    width: 100%;
    padding: 2rem;
    gap: 2rem;
  }
}
@media screen and (max-width: 550px) {
  .k3study_contact {
    padding: 1rem;
  }
  .k3study_contact-card-inputs {
    flex-direction: column;
  }
  .k3study_contact-card-button {
    color: var(--color-bg);
    background-color: var(--color-blue);
    padding: 0.8rem 1rem;
    max-width: 8rem;
    border: none;
    border-radius: 0.2rem;
    margin: 1rem auto;
    font-size: 1.2rem;
  }
}
