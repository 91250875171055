.k3study_pte_span {
  font-weight: 600;
}
.k3study_about-pte-paragraph {
  line-height: 2rem;
  font-weight: 400;
  word-spacing: 0.04em;
  text-align: justify;
}
.k3study_about-pte_main {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (max-width: 900px) {
  .k3study_about-pte_main {
    padding: 2rem;
    text-align: center;
  }
}
@media screen and (max-width: 550px) {
  .k3study_about-pte_main {
    padding: 2rem 1rem;
  }
}
