.k3study-red-card,
.k3study-purple-card,
.k3study-yellow-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2rem;
  padding: 2rem 1rem;
  border-radius: 0.5rem;
  width: 30rem;
}
.k3study-red-card-heading {
  color: var(--color-red);
}
.k3study-purple-card-heading {
  color: var(--color-violet);
}
.k3study-yellow-card-heading {
  color: var(--color-yellow);
}
.k3study-blue-card-heading {
  color: var(--color-blue);
}
.k3study-paragraph {
  color: var(--color-paragraph);
  line-height: 2rem;
}
.k3study-red-card {
  background-color: var(--color-peach);
}
.k3study-yellow-card {
  background-color: var(--color-cream);
}
.k3study-purple-card {
  background-color: var(--color-lavender);
}
.k3study-white-card {
  background-color: var(--color-bg);
  border: 1px solid var(--color-grey);
  padding: 2rem 1rem;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.k3study-red-card-button {
  border: 1px solid var(--color-red);
  color: var(--color-red);
  background: none;
  border-radius: 5rem;
  padding: 0.5rem 1rem;
  margin-block-start: auto;
}
.k3study-white-card-button {
  border: 1px solid var(--color-blue);
  color: var(--color-blue);
  background: none;
  border-radius: 5rem;
  padding: 0.5rem 1rem;
  margin-block-start: auto;
}
.k3study-yellow-card-button {
  border: 1px solid var(--color-yellow);
  color: var(--color-yellow);
  background: none;
  border-radius: 5rem;
  padding: 0.5rem 1rem;
  margin-block-start: auto;
}
.k3study-purple-card-button {
  border: 1px solid var(--color-violet);
  color: var(--color-violet);
  background: none;
  border-radius: 5rem;
  padding: 0.5rem 1rem;
  margin-block-start: auto;
}
.k3study-paragraph {
  line-height: 1.5rem;
}
.k3study-white-card:hover {
  background-color: var(--color-blue);
  color: var(--color-bg);
}
.k3study-white-card:hover .k3study-paragraph {
  background-color: var(--color-blue);
  color: var(--color-bg);
}
.k3study-white-card:hover .k3study-white-card-button {
  border: 1px solid var(--color-bg);
  color: var(--color-bg);
}
@media screen and (max-width: 900px) {
  .k3study-red-card,
  .k3study-purple-card,
  .k3study-yellow-card {
    gap: 0.5rem;
    padding: 1rem 1rem;
    width: auto;
    height: auto;
  }
}
