.k3study_ieletscards-main {
  display: flex;
  gap: 1rem;
  padding: 1rem 3rem;
}
.k3study_quote_text {
  color: var(--color-bg);
  letter-spacing: 0.03em;
  font-size: 2rem;
  font-weight: 500;
}
.k3study_quote_btn {
  background-color: var(--color-blue);
  color: var(--color-bg);
  border-radius: 10px;
  padding: 1rem;
  margin-inline: 2rem;
  white-space: nowrap;
}
.k3study_get-quotes {
  background-color: var(--color-quote-bg);
  width: 100%;
  display: flex;
  padding: 3rem 2rem;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0rem;
}
@media screen and (max-width: 950px) {
  .k3study_ieletscards-main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 5rem;
  }
  .k3study_quote_text {
    color: var(--color-bg);
    letter-spacing: 0.03em;
    font-size: 2rem;
    font-weight: 500;
  }
  .k3study_quote_btn {
    background-color: var(--color-blue);
    color: var(--color-bg);
    border-radius: 10px;
    padding: 1rem;
    margin-inline: 2rem;
    white-space: nowrap;
  }
  .k3study_get-quotes {
    background-color: var(--color-quote-bg);
    width: 100%;
    display: flex;
    padding: 3rem 2rem;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0rem;
  }
}
@media screen and (max-width: 550px) {
  .k3study_ieletscards-main {
    padding: 1rem 3rem;
  }
  .k3study_quote_text {
    font-size: 1rem;
  }
  .k3study_quote_btn {
    margin: 1rem;
    font-size: 1rem;
  }
  .k3study_get-quotes {
    padding: 0rem 1rem;
    margin: 1rem 0rem;
    font-size: 0.5rem;
  }
}
