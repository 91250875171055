.k3study_testimonial-card-rating {
  text-align: center;
}
.k3study_testimonial-card-name {
  text-align: center;
}

.k3study_testimonial-card:hover {
  color: var(--color-bg);
  background-color: var(--color-blue);
}
.k3study_testimonial-card p {
  text-align: center;
}
.k3study_testimonial-card-stars {
  width: 100%;
  text-align: center;
}
.k3study_testimonial-card {
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid var(--color-grey);
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 2rem;
}
.k3study_testimonial-card_img-container {
  padding: 3.5rem;
}
.k3study_testimonial-card-img {
  position: absolute;
  top: -4rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0.8rem;
  border-radius: 70%;
  border: 1px solid var(--color-bg);
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.264) 0%,
    rgba(255, 255, 255, 0.066) 100%
  );
  backdrop-filter: blur(8.42857px);
}
.k3study_testimonial-card_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.8rem;
  margin-inline: auto;
}
.k3study_testimonial_stars {
  display: flex;
}
.k3study_testimonial-card-main {
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 4rem;
}

@media screen and (max-width: 950px) {
  .k3study_testimonial-card-main {
    display: grid;
    grid-template-columns: auto auto;
    margin: 2rem;
    padding: 2rem 0rem;
  }
  .k3study_testimonial-card p {
    font-size: 0.8rem;
  }
  .k3study_testimonial-card {
    height: 20rem;
    margin: 2rem 1rem;
  }
  .k3study_testimonial-card_img-container {
    padding: 2rem;
  }
  .k3study_testimonial-card-img {
    width: 8rem;
  }
}
@media screen and (max-width: 550px) {
  .k3study_testimonial-card-main {
    grid-template-columns: auto;
  }
}
