.k3study_practice-blue {
  color: var(--color-blue);
  font-size: 3rem;
}
.k3study_practice-main {
  display: flex;
  background-color: var(--color-img-bg);
  align-items: center;
  padding: 2rem 3rem;
}
.k3study_practice-section1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.k3study_practice-section2 {
  width: 50%;
  display: flex;
  justify-content: center;
}
.k3study_practice-section2-img {
  width: 100%;
}
.k3study_practice_title {
  font-size: 1.5rem;
  color: var(--color-grey);
}
.k3study_practice_desc {
  width: 80%;
  line-height: 1.6rem;
}
.k3study_practice-section2_mobile {
  display: none;
}
@media screen and (max-width: 900px) {
  .k3study_practice-blue {
    font-size: 1.5rem;
  }
  .k3study_practice-main {
    flex-direction: column;
    background-color: var(--color-img-bg);
    padding: 3rem 1rem;
  }
  .k3study_practice-section1 {
    width: 100%;
    text-align: center;
    gap: 1rem;
  }
  .k3study_practice-section2 {
    display: none;
  }
  .k3study_practice_title {
    font-size: 0.9rem;
  }
  .k3study_practice_desc {
    width: 100%;
  }
  .k3study_practice-section2_mobile {
    display: flex;
    justify-content: center;
  }
  .k3study_practice_img {
    width: 25rem;
  }
}
