.k3study_about-business {
  text-align: center;
  padding: 5rem 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.k3study_about-business h2 {
  font-size: 3rem;
}
.k3study_about-business h4 {
  font-size: 2rem;
}
.k3study_about-business h6 {
  font-size: 1.2rem;
}
.k3study_about-business p {
  font-size: 1rem;
  text-align: center;
  font-weight: 550;
  color: var(--color-grey);
}
.k3study_about-business-choose-us {
  padding: 5rem 4rem;
}
.k3study_about-business-choose-us_main {
  display: flex;
  gap: 2rem;
}
.k3study_about-business-choose-us-section1 {
  padding: 2rem;
}
.k3study_about-business-leadership {
  padding: 0rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.k3study_about-business-leadership-main {
  display: flex;
  gap: 5rem;
  justify-content: space-evenly;
}
.k3study_about-business_leader-img{
  font-size: 4rem;
}
@media screen and (max-width: 950px) {
  .k3study_about-business {
    padding: 5rem 5rem;
  }
  .k3study_about-business-choose-us {
    padding: 2rem 1rem;
  }
  .k3study_about-business-leadership-main {
    gap: 2rem;
  }
  .k3study_about-business-leadership-main img {
    width: 13em;
    height: 13em;
    object-fit: cover;
    overflow: hidden;
  }
  .k3study_about-business-leadership-main h4 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 550px) {
  .k3study_about-business {
    padding: 2rem 1rem;
    gap: 1rem;
  }
  .k3study_about-business h2 {
    font-size: 1.5rem;
  }
  .k3study_about-business h4 {
    font-size: 1.2rem;
  }
  .k3study_about-business-choose-us {
    padding: 2rem 0rem;
  }
  .k3study_about-business-choose-us_main {
    flex-direction: column;
    gap: 0rem;
  }
  .k3study_about-business-leadership {
    padding: 2rem;
    gap: 2rem;
  }
  .k3study_about-business-leadership-main img {
    width: 5em;
    height: 5em;
    object-fit: cover;
    overflow: hidden;
    border-radius: 5rem;
  }
  .k3study_about-business-leadership-main h4 {
    font-size: 1rem;
  }
  .k3study_about-business-leadership-main p {
    font-size: 0.8rem;
  }
}
