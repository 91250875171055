.k3study_about-oet {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.5rem;
  text-align: justify;
}
.k3study_about-oet-paragraph {
  font-weight: 500;
  line-height: 3rem;
  padding: 3rem;
}
.k3study_about-oet-main {
  display: flex;
  padding: 2rem;
}
.k3study_text-blue {
  color: var(--color-blue);
}
.k3study_about-oet-heading {
  font-size: 3rem;
  font-weight: 550;
}
.k3study_about-oet-section1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 2rem;
}
.k3study_about-oet-section2 {
  width: 50%;
  display: flex;
  justify-content: space-around;
  padding: 2rem;
}
.k3study_about-oets-healthcare-img1,
.k3study_about-oets-healthcare-img2 {
  border: 1px solid var(--color-blue);
  padding: 0.5rem;
}
.k3study_why-oets-paragraph {
  font-weight: 500;
  line-height: 3rem;
  padding: 0rem 3rem;
}
.k3study_why-oets {
  margin: 4rem 0rem;
  padding: 3rem;
}
.k3study_why-oets-main-content {
  position: absolute;
  z-index: 10;
  top: 30%;
  left: 12%;
  display: flex;
  gap: 2rem;
  margin: 0 auto;
  text-align: center;
}
.k3study_graph-main {
  position: relative;
}
.k3study_why-oets-content-text {
  color: var(--color-bg);
}
.k3study_why-oets-graph {
  width: 100%;
  height: auto;
  object-fit: cover;
  overflow: hidden;
}
.k3study_graph-main_map-img {
  width: 100%;
}
@media screen and (max-width: 1100px) {
  .k3study_about-oet {
    font-size: 1.2rem;
    text-align: center;
  }
  .k3study_about-oet-main {
    padding: 0rem;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    text-align: center;
  }
  .k3study_about-oet-section1 {
    width: 100%;
  }
  .k3study_about-oet-section2 {
    width: 100%;
  }
  .k3study_why-oets-main-content {
    top: 20%;
    left: 15%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 0.5rem;
    gap: 2rem;
  }
  .k3study_why-oets-graph {
    width: 100%;
    height: 30rem;
    object-fit: cover;
    overflow: hidden;
  }
  .k3study_graph-main_map-img {
    width: 50%;
  }
}
@media screen and (max-width: 550px) {
  .k3study_about-oet-paragraph {
    line-height: 2.5rem;
    padding: 1rem;
  }
  .k3study_about-oet-heading {
    font-size: 1.8rem;
  }
  .k3study_about-oet-section2 {
    flex-direction: row;
    padding: 0rem;
  }
  .k3study_about-oets-healthcare-img1,
  .k3study_about-oets-healthcare-img2 {
    border: 1px solid var(--color-blue);
    padding: 0.5rem;
    width: 50%;
  }
  .k3study_about-oets-healthcare-img2 {
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .k3study_why-oets-paragraph {
    line-height: 2.5rem;
    padding: 0rem 0rem;
  }
  .k3study_why-oets {
    margin: 2rem 0rem;
    padding: 1rem;
  }
  .k3study_why-oets-main-content {
    left: 0;
    display: grid;
  }
  .k3study_why-oets-graph {
    height: 20rem;
  }
}
