.k3study_what-is-iletes_main {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (max-width: 950px) {
  .k3study_what-is-iletes_main {
    padding: 2rem;
    text-align: center;
  }
}
@media screen and (max-width: 550px) {
  .k3study_what-is-iletes_main {
    padding: 2rem 1rem;
    font-size: 0.8rem;
  }
}
