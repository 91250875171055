.k3study_testmonials-testimonials {
  text-align: center;
  font-size: 1rem;
}
.k3study_testmonials_title {
  text-align: center;
  font-size: 3rem;
  color: var(--color-text);
  font-weight: 600;
}
.k3study_testimonials-boxhead {
  display: flex;
  gap: 2rem;
}
.k3study_testimonials-star {
  display: flex;
}
.k3study_testimonials-main {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.k3study_testimonials-content {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.k3study_testimonials-card {
  display: flex;
  flex-direction: column;
  /* width: 25rem; */
  gap: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  /* border: 2px solid red; */
}
.k3study_testimonials_student_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.k3study_testimonials_student-name {
  font-weight: 600;
}
.k3study_testimonials_role,
.k3study_testimonials_rating-text {
  font-size: 0.9rem;
}
.k3study_testimonials_decs {
  font-size: 0.9rem;
  color: var(--color-text);
}
.k3study_testimonials_btns {
  background-color: var(--color-img-bg);
  padding: 1rem 2rem;
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  margin-inline: auto;
  border-radius: 0.3rem;
}
.k3study_testimonials_btn {
  background-color: var(--color-skyblue);
  padding: 0.3rem 2rem;
}

.k3study_testimonials_student_img{
  font-size: 3rem;
}
.k3study_testimonials_student_div{
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}
@media screen and (max-width: 950px) {
  .k3study_testmonials-testimonials {
    font-size: 0.8rem;
  }
  .k3study_testmonials_title {
    font-size: 1.5rem;
  }
  .k3study_testimonials-content {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
  .k3study_testimonials-card {
    width: auto;
    padding: 1rem 1rem;
    text-align: center;
  }
  .k3study_testimonials_student_info {
    flex-direction: column;
    justify-content: center;
  }
  .k3study_testimonials_btns {
    gap: 1.5rem;
  }
  .k3study_testimonials_student_div{
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 550px) {
  .k3study_testimonials-boxhead {
    display: flex;
    gap: 2rem;
  }
  .k3study_testimonials-content {
    flex-direction: column;
  }
  .k3study_testimonials_student_info {
    align-items: center;
    justify-content: space-between;
  }
}
