.k3study_about-sat-span {
  font-weight: 600;
}
.k3study_about-sat-list-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.k3study_about-sat-bold {
  font-weight: 600;
}
.k3study_pte_span {
  font-weight: 600;
}
.k3study_about-sat-paragraph {
  line-height: 2rem;
  font-weight: 400;
  word-spacing: 0.04em;
  text-align: justify;
}
.k3study_about-sat_main {
  padding: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.k3study_about-sat_li {
  list-style: disc;
  padding: 0.5rem;
  text-align: justify;
}
.k3study_heading {
  color: var(--color-heading);
  font-size: 2rem;
}
@media screen and (max-width: 900px) {
  .k3study_about-sat_main {
    padding: 2rem 1rem;
    text-align: center;
  }
  .k3study_about-sat-list {
    margin-inline: 2rem;
    text-align: left;
  }
  .k3study_about-sat-list-item {
    text-align: start;
    padding-left: 1rem;
  }
}
