.k3study-introduction-main {
  display: flex;
  align-items: center;
  padding: 2rem;
}
.k3study-introduction-blue {
  color: var(--color-blue);
}
.k3study-introduction-paragraph {
  color: var(--color-paragraph);
  width: 90%;
}
.k3study-introduction-segments_main {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 0rem;
}
.k3study-introduction-segments {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.3rem;
}
.k3study_introduction-who-we-are {
  font-weight: 600;
}
.k3study_introduction-segment-text {
  color: var(--color-text);
}
.k3study_introduction-button {
  background-color: var(--color-blue);
  color: var(--color-bg);
  border: none;
  padding: 1rem 4rem;
  border-radius: 10px;
}
.k3study-introduction-section1 {
  width: 50%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0rem 2rem;
}
.k3study-introduction-section2 {
  width: 50%;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
}
.k3study_introduction_ladyBg {
  position: absolute;
  bottom: 0rem;
  z-index: -1;
  width: 25rem;
}
.k3study_introduction-main-img {
  width: 22rem;
}
.k3study_introduction_bubble {
  position: absolute;
  top: 0;
  right: 5rem;
}
.k3study_introduction_pinkBubble {
  position: absolute;
  bottom: 8rem;
  left: 3rem;
}
.k3study_introduction_greenChat {
  position: absolute;
  bottom: 0;
  right: 5rem;
}
.k3study_introduction_chat {
  position: absolute;
  top: 0;
  left: 5rem;
}
.k3study_introduction_teachers {
  position: absolute;
  bottom: 0rem;
  right: 0;
}
.k3study_introduction_clients {
  position: absolute;
  top: 5rem;
  left: 0;
}
.k3study_introduction_students {
  position: absolute;
  bottom: -4rem;
  left: 0;
}
.k3study-introduction-section2_mobile {
  display: none;
}
@media screen and (max-width: 900px) {
  .k3study-introduction-main {
    padding: 1rem;
    text-align: center;
  }
  .k3study-introduction-paragraph {
    width: 100%;
  }
  .k3study-introduction-segments_main {
    flex-direction: row;
    gap: 1rem;
  }
  .k3study-introduction-section1 {
    width: 100%;
    gap: 0.5rem;
    align-items: center;
  }
  .k3study-introduction-section2 {
    display: none;
  }
  .k3study-introduction-section2_mobile {
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .k3study_introduction_ladyBg {
    width: 20rem;
  }
  .k3study_introduction-main-img {
    width: 18rem;
  }
  .k3study_introduction_bubble {
    top: 0.5rem;
    right: 0rem;
  }
  .k3study_introduction_pinkBubble {
    left: -1rem;
  }
  .k3study_introduction_greenChat {
    right: -1rem;
  }
  .k3study_introduction_chat {
    top: 1rem;
    left: 0rem;
  }
  .k3study_introduction_teachers {
    right: -2rem;
    width: 10rem;
  }
  .k3study_introduction_clients {
    top: 5rem;
    left: -2rem;
    width: 10rem;
  }
  .k3study_introduction_students {
    bottom: -2rem;
    left: -2rem;
    width: 10rem;
  }
}
